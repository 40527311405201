export const bussinessClintUrl = [
  "1M6sYJAPyHEcoOr2MDu7LQVV2O5gO_6Lj",
  "1LvKXTSIIyL-jaYEjXFwb0pBquisCyIHi",
  "1IeiKnRW8lYZtC3wp1sKx1601LGDafIv8",
  "1IVEW0FNSHWOpqd80yrcDu9WogK7PKfMm",
  "1wyfXtMydsGUL5j0fNQsib9iJ8zP8Z_vo",
  "1wuZPxzZhgZ6vVh2cNwr3Poyxk5Q8egFs",
  "1HuM6tl9H8_GJR4_si3_CzNN6JjSD0jZl",
  // "1IfUitOu4P7NSpN6PMbSHCJcgW5oaHeDz",
  "1Ip3z0XpdRG8v_N0xwgo3QKuAAgPQtna1",
  "1JJDbaes0Rfd0VMKSdolS3QRRY5Tlu4MF",
  "1I780NU7o8n9kxqK4fbWo-lA26M6izR4N",
  "1JF6-mMQCUePB1J6o3iKAYpl9-pBQvqAT",
  "1IeJ8HVsa7VCZ8Ub1K7cnRyUk3WDIaeLP",
  "1J9uMX3O-YrMCwzYcIFXdVdPWYMs6GoGj",
  "1I6Q3LQDlnMOQG-sA5pMdjRawh9YY96Q3",
  "1HwGUldiiOdqP2CFlhHN57kV_B9QkEZ0O",
  "1JJHD9IQgupPzY9jYzARDAHCnTXjqDRGy",
  "1JJMggiJZT3vEXeV5xu3YNx51EkKZgCYn",
  "1JPJU8DhAWizgyID-lUsK0KZbQHX1WxlG",
];
