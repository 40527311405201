import React, { CSSProperties, FC } from "react";
type InvertedCommaProp = {
  className?: string;
  width: string;
  height: string;
  style?: CSSProperties;
};
const Tick: FC<InvertedCommaProp> = ({ className, width, height, style }) => {
  return (
    <div
      className={`${className} curve-container b400 bundle-bb-curve-container`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={style}
        fill="white"
        className="bi bi-check"
        viewBox="0 0 16 16"
      >
        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
      </svg>
    </div>
  );
};
export default Tick;
