import { Marker } from "react-globe";

const markers: Marker[] = [
  {
    id: "1",
    city: "Kathmandu",
    color: "red",
    coordinates: [27.7172, 85.324],
    value: 50,
  },
  {
    id: "2",
    city: "Pokhara",
    color: "red",
    coordinates: [28.2096, 83.9856],
    value: 50,
  },
  {
    id: "3",
    city: "Lumbini",
    color: "red",
    coordinates: [27.4833, 83.2764],
    value: 50,
  },
  {
    id: "4",
    city: "Paro",
    color: "red",
    coordinates: [27.4287, 89.4164],
    value: 50,
  },
  {
    id: "5",
    city: "Thimpu",
    color: "red",
    coordinates: [27.4716, 89.6386],
    value: 50,
  },
  {
    id: "6",
    city: "Punakha",
    color: "red",
    coordinates: [27.5921, 89.8797],
    value: 50,
  },
  {
    id: "7",
    city: "Dhaka",
    color: "red",
    coordinates: [23.8041, 90.4152],
    value: 50,
  },
  {
    id: "8",
    city: "Cox Bajar",
    color: "red",
    coordinates: [21.4512, 92.0085],
    value: 50,
  },
  {
    id: "9",
    city: "Bandarban",
    color: "red",
    coordinates: [22.1936, 92.2187],
    value: 50,
  },
  {
    id: "10",
    city: "Sylhet",
    color: "red",
    coordinates: [24.9048, 91.86],
    value: 50,
  },
  {
    id: "11",
    city: "Colombo",
    color: "red",
    coordinates: [6.9271, 79.8612],
    value: 50,
  },
  {
    id: "12",
    city: "Kandy",
    color: "red",
    coordinates: [7.2906, 80.6337],
    value: 50,
  },
  {
    id: "13",
    city: "Ella",
    color: "red",
    coordinates: [72.85, 25.0333],
    value: 50,
  },
  {
    id: "14",
    city: "Galle",
    color: "red",
    coordinates: [6.0329, 80.2168],
    value: 50,
  },
  {
    id: "15",
    city: "Mirissa",
    color: "red",
    coordinates: [5.9483, 80.4716],
    value: 50,
  },
  {
    id: "16",
    city: "HoChiMinh",
    color: "red",
    coordinates: [10.8231, 106.6297],
    value: 50,
  },
  {
    id: "17",
    city: "Dalat",
    color: "red",
    coordinates: [11.9404, 108.4583],
    value: 50,
  },
  {
    id: "18",
    city: "PhongNha",
    color: "red",
    coordinates: [17.5903, 106.2831],
    value: 50,
  },
  {
    id: "19",
    city: "Hanoi",
    color: "red",
    coordinates: [21.0278, 105.8342],
    value: 50,
  },
  {
    id: "20",
    city: "Sapa",
    color: "red",
    coordinates: [22.3364, 103.8438],
    value: 50,
  },
  {
    id: "21",
    city: "Hue",
    color: "red",
    coordinates: [16.4637, 107.5909],
    value: 50,
  },
  {
    id: "22",
    city: "Kuala Lampur",
    color: "red",
    coordinates: [3.1319, 101.6841],
    value: 50,
  },
  {
    id: "23",
    city: "Penang",
    color: "red",
    coordinates: [5.4141, 100.3288],
    value: 50,
  },
  {
    id: "24",
    city: "Malacca",
    color: "red",
    coordinates: [2.1896, 102.2501],
    value: 50,
  },
  {
    id: "25",
    city: "Singapore",
    color: "red",
    coordinates: [1.3521, 103.8198],
    value: 50,
  },
  {
    id: "26",
    city: "Bangkok",
    color: "red",
    coordinates: [13.7563, 100.5018],
    value: 50,
  },
  {
    id: "27",
    city: "Krabi",
    color: "red",
    coordinates: [8.0855, 98.9063],
    value: 50,
  },
  {
    id: "28",
    city: "Pattaya",
    color: "red",
    coordinates: [12.9236, 100.8825],
    value: 50,
  },
  {
    id: "29",
    city: "Railay beach",
    color: "red",
    coordinates: [8.0117, 98.8378],
    value: 50,
  },
  {
    id: "30",
    city: "Davao",
    color: "red",
    coordinates: [7.1907, 125.4553],
    value: 50,
  },
  {
    id: "31",
    city: "Boracay",
    color: "red",
    coordinates: [11.9674, 121.9248],
    value: 50,
  },
  {
    id: "32",
    city: "Siargao island",
    color: "red",
    coordinates: [9.8482, 126.0458],
    value: 50,
  },
  {
    id: "33",
    city: "Manila",
    color: "red",
    coordinates: [14.5995, 120.9842],
    value: 50,
  },
  {
    id: "34",
    city: "Pekbeng",
    color: "red",
    coordinates: [19.899, 101.1419],
    value: 50,
  },
  {
    id: "35",
    city: "Vientiane",
    color: "red",
    coordinates: [17.9757, 102.6331],
    value: 50,
  },
  {
    id: "36",
    city: "Luang Prabang",
    color: "red",
    coordinates: [19.8833, 102.1387],
    value: 50,
  },
  {
    id: "37",
    city: "Phenom pheh",
    color: "red",
    coordinates: [11.5564, 104.9282],
    value: 50,
  },
  {
    id: "38",
    city: "Siem Reap",
    color: "red",
    coordinates: [13.3633, 103.8564],
    value: 50,
  },
  {
    id: "39",
    city: "Battambang",
    color: "red",
    coordinates: [13.0957, 103.2022],
    value: 50,
  },
  {
    id: "40",
    city: "Dubai",
    color: "red",
    coordinates: [25.2048, 55.2708],
    value: 50,
  },
  {
    id: "41",
    city: "Almaty",
    color: "red",
    coordinates: [43.222, 76.8512],
    value: 50,
  },
  {
    id: "42",
    city: "Astana",
    color: "red",
    coordinates: [51.1605, 71.4704],
    value: 50,
  },
  {
    id: "43",
    city: "Karaganda",
    color: "red",
    coordinates: [49.8047, 73.1094],
    value: 50,
  },
  {
    id: "44",
    city: "Bali",
    color: "red",
    coordinates: [8.3405, 115.092],
    value: 50,
  },
  {
    id: "45",
    city: "Ubud",
    color: "red",
    coordinates: [8.5069, 115.2625],
    value: 50,
  },
  {
    id: "46",
    city: "Jakarta",
    color: "red",
    coordinates: [6.2088, 106.8456],
    value: 50,
  },
  {
    id: "47",
    city: "Sumatra",
    color: "red",
    coordinates: [0.5897, 101.3431],
    value: 50,
  },
  {
    id: "48",
    city: "Maldives",
    color: "red",
    coordinates: [3.2028, 73.2207],
    value: 50,
  },
  {
    id: "49",
    city: "Amman",
    color: "red",
    coordinates: [31.9539, 35.9106],
    value: 50,
  },
  {
    id: "50",
    city: "Wadi rum",
    color: "red",
    coordinates: [29.5559, 35.4076],
    value: 50,
  },
  {
    id: "51",
    city: "Jerash",
    color: "red",
    coordinates: [32.2747, 35.8961],
    value: 50,
  },
  {
    id: "52",
    city: "Alexandria",
    color: "red",
    coordinates: [31.2001, 29.9187],
    value: 50,
  },
  {
    id: "53",
    city: "Cairo",
    color: "red",
    coordinates: [30.0444, 31.2357],
    value: 50,
  },
  {
    id: "54",
    city: "Luxor",
    color: "red",
    coordinates: [25.6872, 32.6396],
    value: 50,
  },
  {
    id: "55",
    city: "Aswan",
    color: "red",
    coordinates: [24.0889, 32.8998],
    value: 50,
  },
  {
    id: "56",
    city: "Marsa Alam",
    color: "red",
    coordinates: [25.0676, 34.879],
    value: 50,
  },
  {
    id: "57",
    city: "Hurghada",
    color: "red",
    coordinates: [27.2579, 33.8116],
    value: 50,
  },
  {
    id: "58",
    city: "Sharm El Sheikh",
    color: "red",
    coordinates: [27.9654, 34.3618],
    value: 50,
  },
  {
    id: "59",
    city: "Lahore",
    color: "red",
    coordinates: [31.5204, 74.3587],
    value: 50,
  },
  {
    id: "60",
    city: "Islamabad",
    color: "red",
    coordinates: [33.6844, 73.0479],
    value: 50,
  },
  {
    id: "61",
    city: "Karachi",
    color: "red",
    coordinates: [24.8607, 67.0011],
    value: 50,
  },
  {
    id: "62",
    city: "Dubai",
    color: "red",
    coordinates: [25.2048, 55.2708],
    value: 50,
  },
  {
    id: "63",
    city: "Abu dhabi",
    color: "red",
    coordinates: [24.4539, 54.3773],
    value: 50,
  },
  {
    id: "64",
    city: "Sharjah",
    color: "red",
    coordinates: [25.3462, 55.4211],
    value: 50,
  },
  {
    id: "65",
    city: "Muskat",
    color: "red",
    coordinates: [23.588, 58.3829],
    value: 50,
  },
  {
    id: "66",
    city: "Nizwa",
    color: "red",
    coordinates: [22.9171, 57.5363],
    value: 50,
  },
  {
    id: "67",
    city: "Sur",
    color: "red",
    coordinates: [22.5653, 59.5066],
    value: 50,
  },
  {
    id: "68",
    city: "Tehran",
    color: "red",
    coordinates: [35.7219, 51.3347],
    value: 50,
  },
  {
    id: "69",
    city: "Shiraz",
    color: "red",
    coordinates: [29.591768, 52.583698],
    value: 50,
  },
  {
    id: "70",
    city: "Isfahan",
    color: "red",
    coordinates: [32.6539, 51.666],
    value: 50,
  },
  {
    id: "71",
    city: "Yazd",
    color: "red",
    coordinates: [31.8974, 54.3569],
    value: 50,
  },
  {
    id: "72",
    city: "Keshet",
    color: "red",
    coordinates: [32.9804, 35.8085],
    value: 50,
  },
  {
    id: "73",
    city: "Ein Gedi",
    color: "red",
    coordinates: [31.4512, 35.3836],
    value: 50,
  },
  {
    id: "74",
    city: "Jerusalem",
    color: "red",
    coordinates: [31.7683, 35.2137],
    value: 50,
  },
  {
    id: "75",
    city: "Protaras",
    color: "red",
    coordinates: [35.0121, 34.0562],
    value: 50,
  },
  {
    id: "76",
    city: "Ayia Napa",
    color: "red",
    coordinates: [34.9887, 34.0003],
    value: 50,
  },
  {
    id: "77",
    city: "Seol",
    color: "red",
    coordinates: [37.5519, 126.9918],
    value: 50,
  },
  {
    id: "78",
    city: "Busan",
    color: "red",
    coordinates: [35.21, 129.0689],
    value: 50,
  },
  {
    id: "79",
    city: "Jeju Island",
    color: "red",
    coordinates: [33.3846, 126.5535],
    value: 50,
  },
  {
    id: "80",
    city: "Xien",
    color: "red",
    coordinates: [34.2658, 108.9541],
    value: 50,
  },
  {
    id: "81",
    city: "Shanghai",
    color: "red",
    coordinates: [31.2304, 121.4737],
    value: 50,
  },
  {
    id: "82",
    city: "Hongkong",
    color: "red",
    coordinates: [22.3193, 114.1694],
    value: 50,
  },
  {
    id: "83",
    city: "Kutaisi",
    color: "red",
    coordinates: [42.2718, 42.706],
    value: 50,
  },
  {
    id: "84",
    city: "Gori",
    color: "red",
    coordinates: [41.9854, 44.1084],
    value: 50,
  },
  {
    id: "85",
    city: "Tbilisi",
    color: "red",
    coordinates: [41.6938, 44.8015],
    value: 50,
  },
  {
    id: "86",
    city: "Tusheti",
    color: "red",
    coordinates: [42, 45],
    value: 50,
  },
  {
    id: "87",
    city: "Istanbul",
    color: "red",
    coordinates: [41.0082, 28.9784],
    value: 50,
  },
  {
    id: "88",
    city: "Ankara",
    color: "red",
    coordinates: [39.9334, 0],
    value: 50,
  },
  {
    id: "89",
    city: "Konya",
    color: "red",
    coordinates: [37.8746, 0],
    value: 50,
  },
  {
    id: "90",
    city: "Side",
    color: "red",
    coordinates: [36.7769, 0],
    value: 50,
  },
  {
    id: "91",
    city: "Marmaris",
    color: "red",
    coordinates: [36.8549, 0],
    value: 50,
  },
  {
    id: "92",
    city: "Beirut",
    color: "red",
    coordinates: [33.8938, 0],
    value: 50,
  },
  {
    id: "93",
    city: "Batroun",
    color: "red",
    coordinates: [34.2498, 35.6643],
    value: 50,
  },
  {
    id: "94",
    city: "Trablous",
    color: "red",
    coordinates: [34.4346, 35.8362],
    value: 50,
  },
  {
    id: "95",
    city: "Bukhara",
    color: "red",
    coordinates: [39.7681, 64.4556],
    value: 50,
  },
  {
    id: "96",
    city: "Samarkand",
    color: "red",
    coordinates: [39.6508, 66.9654],
    value: 50,
  },
  {
    id: "97",
    city: "Tashkent",
    color: "red",
    coordinates: [41.2995, 69.2401],
    value: 50,
  },
  {
    id: "98",
    city: "Bucharest",
    color: "red",
    coordinates: [44.4268, 26.1025],
    value: 50,
  },
  {
    id: "99",
    city: "Sofia",
    color: "red",
    coordinates: [42.6977, 23.3219],
    value: 50,
  },
  {
    id: "100",
    city: "Veliko",
    color: "red",
    coordinates: [44.8107, 20.5979],
    value: 50,
  },
  {
    id: "101",
    city: "Brasov",
    color: "red",
    coordinates: [45.6427, 25.5887],
    value: 50,
  },
  {
    id: "102",
    city: "Addis Ababa",
    color: "red",
    coordinates: [8.9806, 38.7578],
    value: 50,
  },
  {
    id: "103",
    city: "Axoum",
    color: "red",
    coordinates: [14.134, 38.7473],
    value: 50,
  },
  {
    id: "104",
    city: "Harar",
    color: "red",
    coordinates: [9.3126, 42.1227],
    value: 50,
  },
  {
    id: "105",
    city: "Budapest",
    color: "red",
    coordinates: [47.4979, 19.0402],
    value: 50,
  },
  {
    id: "106",
    city: "Szentendre",
    color: "red",
    coordinates: [47.6795, 19.0669],
    value: 50,
  },
  {
    id: "107",
    city: "Eger",
    color: "red",
    coordinates: [47.9025, 20.3772],
    value: 50,
  },
  {
    id: "108",
    city: "Lisbon",
    color: "red",
    coordinates: [38.7223, 9.1393],
    value: 50,
  },
  {
    id: "109",
    city: "Lagos",
    color: "red",
    coordinates: [37.1028, 8.673],
    value: 50,
  },
  {
    id: "110",
    city: "Aveiro",
    color: "red",
    coordinates: [40.6405, 8.6538],
    value: 50,
  },
  {
    id: "111",
    city: "Porto",
    color: "red",
    coordinates: [41.1579, 8.6291],
    value: 50,
  },
  {
    id: "112",
    city: "Athens",
    color: "red",
    coordinates: [37.9838, 23.7275],
    value: 50,
  },
  {
    id: "113",
    city: "Santorini",
    color: "red",
    coordinates: [36.3932, 25.4615],
    value: 50,
  },
  {
    id: "114",
    city: "Mykonos",
    color: "red",
    coordinates: [37.4415, 25.3667],
    value: 50,
  },
  {
    id: "115",
    city: "Crete",
    color: "red",
    coordinates: [35.2401, 24.8093],
    value: 50,
  },
  {
    id: "116",
    city: "Corfu",
    color: "red",
    coordinates: [39.6243, 19.9217],
    value: 50,
  },
  {
    id: "117",
    city: "London",
    color: "red",
    coordinates: [51.5072, 0.1276],
    value: 50,
  },
  {
    id: "118",
    city: "York",
    color: "red",
    coordinates: [53.9614, 1.0739],
    value: 50,
  },
  {
    id: "119",
    city: "Edinburgh",
    color: "red",
    coordinates: [55.9533, 3.1883],
    value: 50,
  },
  {
    id: "120",
    city: "Cardiff",
    color: "red",
    coordinates: [51.4837, 3.1681],
    value: 50,
  },
  {
    id: "121",
    city: "Paris",
    color: "red",
    coordinates: [48.8566, 0],
    value: 50,
  },
  {
    id: "122",
    city: "Nice",
    color: "red",
    coordinates: [43.7102, 0],
    value: 50,
  },
  {
    id: "123",
    city: "Bordeaux",
    color: "red",
    coordinates: [44.8378, 0],
    value: 50,
  },
  {
    id: "124",
    city: "Marseille",
    color: "red",
    coordinates: [43.2965, 0],
    value: 50,
  },
  {
    id: "125",
    city: "Barcelona",
    color: "red",
    coordinates: [41.3874, 0],
    value: 50,
  },
  {
    id: "126",
    city: "Madrid",
    color: "red",
    coordinates: [40.4168, 0],
    value: 50,
  },
  {
    id: "127",
    city: "Malaga",
    color: "red",
    coordinates: [36.7213, 0],
    value: 50,
  },
  {
    id: "128",
    city: "Sevilla",
    color: "red",
    coordinates: [37.3891, 0],
    value: 50,
  },
  {
    id: "129",
    city: "Berlin",
    color: "red",
    coordinates: [52.52, 0],
    value: 50,
  },
  {
    id: "130",
    city: "Munich",
    color: "red",
    coordinates: [48.1351, 0],
    value: 50,
  },
  {
    id: "131",
    city: "Frankfurt",
    color: "red",
    coordinates: [50.1109, 0],
    value: 50,
  },
  {
    id: "132",
    city: "Hamburg",
    color: "red",
    coordinates: [53.5488, 0],
    value: 50,
  },
  {
    id: "133",
    city: "Bastogne",
    color: "red",
    coordinates: [50.0006, 0],
    value: 50,
  },
  {
    id: "134",
    city: "Brussels",
    color: "red",
    coordinates: [50.8476, 0],
    value: 50,
  },
  {
    id: "135",
    city: "Antwerp",
    color: "red",
    coordinates: [51.2213, 0],
    value: 50,
  },
  {
    id: "136",
    city: "Amsterdam",
    color: "red",
    coordinates: [52.3676, 0],
    value: 50,
  },
  {
    id: "137",
    city: "Hague",
    color: "red",
    coordinates: [52.0705, 0],
    value: 50,
  },
  {
    id: "138",
    city: "Maastricht",
    color: "red",
    coordinates: [50.8514, 0],
    value: 50,
  },
  {
    id: "139",
    city: "Rotterdam",
    color: "red",
    coordinates: [51.9244, 0],
    value: 50,
  },
  {
    id: "140",
    city: "Venice",
    color: "red",
    coordinates: [45.4408, 0],
    value: 50,
  },
  {
    id: "141",
    city: "Roma",
    color: "red",
    coordinates: [41.9028, 0],
    value: 50,
  },
  {
    id: "142",
    city: "Milan",
    color: "red",
    coordinates: [45.4642, 0],
    value: 50,
  },
  {
    id: "143",
    city: "Florence",
    color: "red",
    coordinates: [43.7696, 0],
    value: 50,
  },
  {
    id: "144",
    city: "Zurich",
    color: "red",
    coordinates: [47.3769, 0],
    value: 50,
  },
  {
    id: "145",
    city: "Geneva",
    color: "red",
    coordinates: [46.2044, 0],
    value: 50,
  },
  {
    id: "146",
    city: "Interlatek",
    color: "red",
    coordinates: [46.6863, 0],
    value: 50,
  },
  {
    id: "147",
    city: "Mt Rigi",
    color: "red",
    coordinates: [47.056, 0],
    value: 50,
  },
  {
    id: "148",
    city: "Metterhorn",
    color: "red",
    coordinates: [45.9766, 0],
    value: 50,
  },
  {
    id: "149",
    city: "Tokyo",
    color: "red",
    coordinates: [35.6762, 139.6503],
    value: 50,
  },
  {
    id: "150",
    city: "Osaka",
    color: "red",
    coordinates: [34.6937, 135.5023],
    value: 50,
  },
  {
    id: "151",
    city: "Kyoto",
    color: "red",
    coordinates: [35.0116, 135.7681],
    value: 50,
  },
  {
    id: "152",
    city: "Hiroshima",
    color: "red",
    coordinates: [34.3853, 132.4553],
    value: 50,
  },
  {
    id: "153",
    city: "Perth",
    color: "red",
    coordinates: [31.9523, 115.8613],
    value: 50,
  },
  {
    id: "154",
    city: "Melbourne",
    color: "red",
    coordinates: [37.8136, 144.9631],
    value: 50,
  },
  {
    id: "155",
    city: "Sydney",
    color: "red",
    coordinates: [33.8688, 151.2093],
    value: 50,
  },
  {
    id: "156",
    city: "Auckland",
    color: "red",
    coordinates: [36.8509, 174.7645],
    value: 50,
  },
  {
    id: "157",
    city: "Queenstown",
    color: "red",
    coordinates: [45.0302, 168.6615],
    value: 50,
  },
  {
    id: "158",
    city: "Christchurch",
    color: "red",
    coordinates: [43.532, 172.6306],
    value: 50,
  },
  {
    id: "159",
    city: "Milford",
    color: "red",
    coordinates: [41.2307, 73.064],
    value: 50,
  },
  {
    id: "160",
    city: "Rotorua",
    color: "red",
    coordinates: [38.1446, 176.2378],
    value: 50,
  },
  {
    id: "161",
    city: "Oslo",
    color: "red",
    coordinates: [59.9139, 0],
    value: 50,
  },
  {
    id: "162",
    city: "Bergen",
    color: "red",
    coordinates: [60.3913, 0],
    value: 50,
  },
  {
    id: "163",
    city: "Lillehammer",
    color: "red",
    coordinates: [61.1153, 0],
    value: 50,
  },
  {
    id: "164",
    city: "Alta",
    color: "red",
    coordinates: [69.9687, 0],
    value: 50,
  },
  {
    id: "165",
    city: "Mokle",
    color: "red",
    coordinates: [0, 0],
    value: 50,
  },
  {
    id: "166",
    city: "Reykjavik",
    color: "red",
    coordinates: [64.147, 0],
    value: 50,
  },
  {
    id: "167",
    city: "Keflavic",
    color: "red",
    coordinates: [63.9998, 0],
    value: 50,
  },
  {
    id: "168",
    city: "Jokulsarlon",
    color: "red",
    coordinates: [64.0784, 0],
    value: 50,
  },
  {
    id: "169",
    city: "Vestmannaeyjar",
    color: "red",
    coordinates: [63.4398, 0],
    value: 50,
  },
  {
    id: "170",
    city: "Vik",
    color: "red",
    coordinates: [63.4177, 0],
    value: 50,
  },
  {
    id: "171",
    city: "Stockholm",
    color: "red",
    coordinates: [59.3293, 0],
    value: 50,
  },
  {
    id: "172",
    city: "Gothenburg",
    color: "red",
    coordinates: [57.7089, 0],
    value: 50,
  },
  {
    id: "173",
    city: "Visby",
    color: "red",
    coordinates: [57.6348, 0],
    value: 50,
  },
  {
    id: "174",
    city: "Gotland",
    color: "red",
    coordinates: [57.4684, 0],
    value: 50,
  },

  {
    id: "203",
    city: "Kolkata",
    color: "red",
    coordinates: [22.5726, 88.3639],
    value: 50,
  },
  {
    id: "204",
    city: "Port Blair",
    color: "red",
    coordinates: [11.6234, 92.7265],
    value: 50,
  },
  {
    id: "205",
    city: "Sundarbans",
    color: "red",
    coordinates: [21.9497, 89.1833],
    value: 50,
  },
  {
    id: "206",
    city: "Shillong",
    color: "red",
    coordinates: [25.5788, 91.8933],
    value: 50,
  },
  {
    id: "207",
    city: "Mawlynnong",
    color: "red",
    coordinates: [25.2018, 91.916],
    value: 50,
  },
  {
    id: "208",
    city: "Varanasi",
    color: "red",
    coordinates: [25.3176, 82.9739],
    value: 50,
  },
  {
    id: "209",
    city: "Lucknow",
    color: "red",
    coordinates: [26.8467, 80.9462],
    value: 50,
  },
  {
    id: "210",
    city: "Agra",
    color: "red",
    coordinates: [27.1767, 78.0081],
    value: 50,
  },
  {
    id: "211",
    city: "Delhi",
    color: "red",
    coordinates: [28.7041, 77.1025],
    value: 50,
  },
  {
    id: "212",
    city: "Sreenagar",
    color: "red",
    coordinates: [34.0837, 74.7973],
    value: 50,
  },
  {
    id: "213",
    city: "Sonamarg",
    color: "red",
    coordinates: [34.3032, 75.2931],
    value: 50,
  },
  {
    id: "214",
    city: "Pahelgao",
    color: "red",
    coordinates: [34.0161, 75.315],
    value: 50,
  },
  {
    id: "215",
    city: "Kargil",
    color: "red",
    coordinates: [34.5539, 76.1349],
    value: 50,
  },
  {
    id: "216",
    city: "Leh",
    color: "red",
    coordinates: [34.1526, 77.5771],
    value: 50,
  },
  {
    id: "217",
    city: "Nubra Valley",
    color: "red",
    coordinates: [34.6863, 77.5673],
    value: 50,
  },
  {
    id: "218",
    city: "Pangong",
    color: "red",
    coordinates: [33.7595, 78.6674],
    value: 50,
  },
  {
    id: "219",
    city: "Amritsar",
    color: "red",
    coordinates: [31.634, 74.8723],
    value: 50,
  },
  {
    id: "220",
    city: "Chandigarh",
    color: "red",
    coordinates: [30.7333, 76.7794],
    value: 50,
  },
  {
    id: "221",
    city: "Rishikesh",
    color: "red",
    coordinates: [30.0869, 78.2676],
    value: 50,
  },
  {
    id: "222",
    city: "Haridwar",
    color: "red",
    coordinates: [29.9457, 78.1642],
    value: 50,
  },
  {
    id: "223",
    city: "Dehradun",
    color: "red",
    coordinates: [30.3165, 78.0322],
    value: 50,
  },
  {
    id: "224",
    city: "Mussoorie",
    color: "red",
    coordinates: [30.4598, 78.0644],
    value: 50,
  },
  {
    id: "225",
    city: "Jaipur",
    color: "red",
    coordinates: [26.9124, 75.7873],
    value: 50,
  },
  {
    id: "226",
    city: "Udaipur",
    color: "red",
    coordinates: [24.5854, 73.7125],
    value: 50,
  },
  {
    id: "227",
    city: "Ajmer",
    color: "red",
    coordinates: [26.4499, 74.6399],
    value: 50,
  },
  {
    id: "228",
    city: "Bikaner",
    color: "red",
    coordinates: [28.0229, 73.3119],
    value: 50,
  },
  {
    id: "229",
    city: "Jodhpur",
    color: "red",
    coordinates: [26.2389, 73.0243],
    value: 50,
  },
  {
    id: "230",
    city: "Jaisalmer",
    color: "red",
    coordinates: [26.9157, 70.9083],
    value: 50,
  },
  {
    id: "231",
    city: "Vadodara",
    color: "red",
    coordinates: [22.3072, 73.1812],
    value: 50,
  },
  {
    id: "232",
    city: "Mumbai",
    color: "red",
    coordinates: [19.076, 72.8777],
    value: 50,
  },
  {
    id: "233",
    city: "Aurangabad",
    color: "red",
    coordinates: [19.8762, 75.3433],
    value: 50,
  },
  {
    id: "234",
    city: "Hampi",
    color: "red",
    coordinates: [15.335, 76.46],
    value: 50,
  },
  {
    id: "235",
    city: "Goa",
    color: "red",
    coordinates: [15.2993, 74.124],
    value: 50,
  },
  {
    id: "236",
    city: "Hyderabad",
    color: "red",
    coordinates: [17.385, 78.4867],
    value: 50,
  },
  {
    id: "237",
    city: "Bengaluru",
    color: "red",
    coordinates: [12.9716, 77.5946],
    value: 50,
  },
  {
    id: "238",
    city: "Mysore",
    color: "red",
    coordinates: [12.2958, 76.6394],
    value: 50,
  },
  {
    id: "239",
    city: "Cochin",
    color: "red",
    coordinates: [9.9312, 76.2673],
    value: 50,
  },
  {
    id: "240",
    city: "Thiruvananthapuram",
    color: "red",
    coordinates: [8.5241, 76.9366],
    value: 50,
  },
  {
    id: "241",
    city: "Alleppey",
    color: "red",
    coordinates: [9.4981, 76.3388],
    value: 50,
  },
  {
    id: "242",
    city: "Varkala",
    color: "red",
    coordinates: [8.7379, 76.7163],
    value: 50,
  },
  {
    id: "243",
    city: "Kovalam",
    color: "red",
    coordinates: [8.3988, 76.982],
    value: 50,
  },
  {
    id: "244",
    city: "Kanyakumari",
    color: "red",
    coordinates: [8.0844, 77.5495],
    value: 50,
  },
  {
    id: "245",
    city: "Chennai",
    color: "red",
    coordinates: [13.0827, 80.2707],
    value: 50,
  },
  {
    id: "246",
    city: "Manali",
    color: "red",
    coordinates: [32.2432, 77.1892],
    value: 50,
  },
  {
    id: "247",
    city: "Kasol",
    color: "red",
    coordinates: [32.01, 77.315],
    value: 50,
  },
  {
    id: "248",
    city: "Shimla",
    color: "red",
    coordinates: [31.1048, 77.1734],
    value: 50,
  },
  {
    id: "249",
    city: "Auroville",
    color: "red",
    coordinates: [12.0052, 79.8069],
    value: 50,
  },
  {
    id: "250",
    city: "Mathura",
    color: "red",
    coordinates: [27.4924, 77.6737],
    value: 50,
  },
  {
    id: "251",
    city: "Vrindavan",
    color: "red",
    coordinates: [27.565, 77.6593],
    value: 50,
  },
  {
    id: "252",
    city: "Bodh gaya",
    color: "red",
    coordinates: [24.6961, 84.987],
    value: 50,
  },
  {
    id: "253",
    city: "Vaishali",
    color: "red",
    coordinates: [25.6838, 85.355],
    value: 50,
  },
  {
    id: "254",
    city: "Ooty",
    color: "red",
    coordinates: [11.4102, 76.695],
    value: 50,
  },
  {
    id: "255",
    city: "Madurai",
    color: "red",
    coordinates: [9.9252, 78.1198],
    value: 50,
  },
  {
    id: "256",
    city: "Thanjavur",
    color: "red",
    coordinates: [10.787, 79.1378],
    value: 50,
  },
  {
    id: "257",
    city: "Tadoba",
    color: "red",
    coordinates: [20.2718, 79.3876],
    value: 50,
  },
  {
    id: "258",
    city: "Gir NP",
    color: "red",
    coordinates: [21.1243, 70.8242],
    value: 50,
  },
  {
    id: "259",
    city: "Corbett NP",
    color: "red",
    coordinates: [29.53, 78.7747],
    value: 50,
  },
  {
    id: "260",
    city: "Singalila NP",
    color: "red",
    coordinates: [27.0371, 88.0762],
    value: 50,
  },
  {
    id: "261",
    city: "Ranthambore",
    color: "red",
    coordinates: [26.0173, 76.5026],
    value: 50,
  },
  {
    id: "262",
    city: "Bandhavgarh",
    color: "red",
    coordinates: [23, 80],
    value: 50,
  },
  {
    id: "263",
    city: "Kanha",
    color: "red",
    coordinates: [22.2816, 80.6181],
    value: 50,
  },
  {
    id: "264",
    city: "Dirang",
    color: "red",
    coordinates: [27.3495, 92.2782],
    value: 50,
  },
  {
    id: "265",
    city: "Tawang",
    color: "red",
    coordinates: [27.6325, 91.7539],
    value: 50,
  },
  {
    id: "266",
    city: "Bomdila",
    color: "red",
    coordinates: [27.2645, 92.4159],
    value: 50,
  },
  {
    id: "267",
    city: "Kaziranga NP",
    color: "red",
    coordinates: [26.5775, 93.1711],
    value: 50,
  },
  {
    id: "268",
    city: "Shivasagar",
    color: "red",
    coordinates: [17, 73],
    value: 50,
  },
  {
    id: "269",
    city: "Mon town",
    color: "red",
    coordinates: [26.7331, 95.0615],
    value: 50,
  },
  {
    id: "270",
    city: "Mon town",
    color: "red",
    coordinates: [26.7331, 95.0615],
    value: 50,
  },
  {
    id: "271",
    city: "Kohima",
    color: "red",
    coordinates: [25.6751, 94.1086],
    value: 50,
  },
  {
    id: "272",
    city: "Imphal",
    color: "red",
    coordinates: [24.817, 93.9368],
    value: 50,
  },
  {
    id: "273",
    city: "Loktak",
    color: "red",
    coordinates: [24.5593, 93.8147],
    value: 50,
  },
  {
    id: "274",
    city: "Agartala",
    color: "red",
    coordinates: [23.8315, 91.2868],
    value: 50,
  },
  {
    id: "275",
    city: "Aizawl",
    color: "red",
    coordinates: [23.7307, 92.7173],
    value: 50,
  },
  {
    id: "276",
    city: "Guwahati",
    color: "red",
    coordinates: [26.1158, 91.7086],
    value: 50,
  },
  {
    id: "277",
    city: "Periyar NP",
    color: "red",
    coordinates: [9.4622, 77.2368],
    value: 50,
  },
];
export default markers;
