export const policies = {
  topic: "Cancellation and Refund Policy",
  description:
    "The user will find all details regarding the booking policies, cancellation charges, refund policies and other additional information on this page. Unlike other platforms Lyamii kept its policy notes simple and tidy for the user. We do recommend all our international and domestic traveller friends to kindly go through these policy and agreement pages as it balances the trust bridge between the user and the service provider.",
  policies: [
    {
      policy: "Booking Policy",
      text: [
        "After logging in Lyamii an user will find ‘book now’ and ‘buy now’ option on each tour. Lyamii recommends our new users and page visitors to opt for the ‘book now’.",
        "Users with at least one Lyamii vacation may avail the ‘buy now’ option as prepaid tours include special additions and flat 5% off. ",
        "Although the pricing goes up and down as per the season, the booking cost stays minimum throughout the year. It's 800INR / 24USD / 20GBP / 22EUR / 80AED / 22CHF / 38AUD.",
        "Users with the buy now option receive a Welcome box 10 days after the purchase and a Destination box 10 days prior the departure. ",
        "A tour ID generated with Buy Now includes all pre-tour services, domestic flights, airport pickup drop, accommodations, cuisines, currency exchange, medical and travel insurance, one on one volunteer assistance, transfers, entry passes, activities, surprise boxes, souvenir cart, journal and round the clock multilingual support.",
        "A tour ID generated with Book Now also includes everything mentioned in the earlier note, but the tour ID gets activated only when he/she arrives at the booked destination and completes the documentation and payment. Lyamii arranges airport pickup and table reservation with a Lyamii agent to smoothen the process.",
        "Lyamii operates doorstep documentation and transactions on both online-offline modes for domestic users. The tour IDs of the user with the ‘book now’ option get activated after a Lyamii agent meets him in person and completes the pre-tour process. ",
        "Lyamii Tour Experts get assigned to each sold vacation IDs to verify the user profile and answer all queries and doubts of the user on a virtual meet. The LTEs also explain the daily itinerary, cultural importance of each location, the people, the language layers to the guest. ",
        "The LTEs also assist the user to understand his/her most suitable tour ID. The user may also register and merge new traveller IDs to the same tour ID during this session.",
        "We believe each of the tour IDs offer an unique flavour on the table, with the virtual meet Lyamii understands the traveller’s requirements and make sure the traveller is spending on the right vacation, with the best returns.",
        "Any coupon codes received by the user from any restaurant or hostel or youtube or instagram or on Lyamii brochure can be redeemed during the pre-tour documentation process. ",
        "",
      ],
    },
    {
      policy: "Cancellation Charges",
      text: [
        "Lyamii guarantees the most user-friendly cancellation and refund structure in the market. ",
        "Once a platform visitor books a tour ID on Lyamii he/she receives a confirmation call within 2-6 hours from our operations backend to check if the booking was placed by mistake. Lyamii charges 0% in these scenarios as we reflect the whole paid amount to the user's source account and share the TAT with the user.",
        "Once a platform visitor buys a tour ID on Lyamii he/she also receives a confirmation call within 2-4 hours from our operations backend to check if the purchase was completed by mistake. Lyamii charges 0% in these scenarios too, we reflect the whole paid amount to the user's source account and share the TAT with the user, no questions asked.",
        "If a purchased tour gets cancelled 20 days prior to the arrival date, 100% refund gets credited to the user's source account.",
        "If a purchased tour gets cancelled 14 days prior to the arrival date, we reflect 90% of the paid amount to the user's source account. The user will receive the remaining 10% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "If a purchased tour gets cancelled 7 days prior to the arrival date, 80% refund gets reverted to the source account. The user will receive another 10% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "If a user cancels a purchased tour ID within the week of arrival, he/she will receive a 70% refund to his/her source account. The user will receive another 5% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "If a user cancels a purchased tour ID on the day of arrival, he/she will receive a 30% refund to his/her source account. The user will receive another 20% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "The minimum token amount of the booked tours are non refundable, although the user with a booked tour may reschedule the vacation with the same paid token money. But the rescheduled date shouldn't exceed 90 days from the previously booked vacation. ",
        "These foreign transactions go through a few government taxes, so 100% refund refers to the paid amount minus the taxes.",
        "The currency exchange rate always follows the live exchange value, in case of cash payments Lyamii operates offline transactions and currency exchange only in the common spaces of the 4 star plus property that the guest is staying in.",
        "In cases of domestic user booked tour IDs gets confirmed after the doorstep documentation and payment or the virtual meet documentation and payment. ",
      ],
    },
    {
      policy: "Refund Policy ",
      text: [
        "Lyamii’s payment and refund unit is committed to excellence and top notch user experience.",
        "Lyamii initiates refunds within 2 hours from the cancellation confirmation and shares the TAT with the user over mail highlighting the transaction ID. ",
        "All refunds get credited to the user's source account only, Lyamii doesn't engage with third party banking services. ",
        "Refund TAT depends on the user's payment medium. Some cards take 4-7 days to process refunds where upi payments take 2 hours.",
        "In cases of international users the refund amount might fluctuate a bit as the transactions go through live exchange value and a few government taxes.",
        "In cases of transaction failures the user may reach our Bootcamp with the transaction ID at connect.bootcamp@lyamii.com. Our dedicated team guarantees a solution within 24 hours.",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    },
  ],
};

export const privacyPolicies = {
  topic: "Privacy Policy",
  description:
    "The user will find all details regarding the booking policies, cancellation charges, refund policies and other additional information on this page. Unlike other platforms Lyamii kept its policy notes simple and tidy for the user. We do recommend all our international and domestic traveller friends to kindly go through these policy and agreement pages as it balances the trust bridge between the user and the service provider.",
  policies: [
    {
      policy: "Booking Policy",
      text: [
        "After logging in Lyamii an user will find ‘book now’ and ‘buy now’ option on each tour. Lyamii recommends our new users and page visitors to opt for the ‘book now’.",
        "Users with at least one Lyamii vacation may avail the ‘buy now’ option as prepaid tours include special additions and flat 5% off. ",
        "Although the pricing goes up and down as per the season, the booking cost stays minimum throughout the year. It's 800INR / 24USD / 20GBP / 22EUR / 80AED / 22CHF / 38AUD.",
        "Users with the buy now option receive a Welcome box 10 days after the purchase and a Destination box 10 days prior the departure. ",
        "A tour ID generated with Buy Now includes all pre-tour services, domestic flights, airport pickup drop, accommodations, cuisines, currency exchange, medical and travel insurance, one on one volunteer assistance, transfers, entry passes, activities, surprise boxes, souvenir cart, journal and round the clock multilingual support.",
        "A tour ID generated with Book Now also includes everything mentioned in the earlier note, but the tour ID gets activated only when he/she arrives at the booked destination and completes the documentation and payment. Lyamii arranges airport pickup and table reservation with a Lyamii agent to smoothen the process.",
        "Lyamii operates doorstep documentation and transactions on both online-offline modes for domestic users. The tour IDs of the user with the ‘book now’ option get activated after a Lyamii agent meets him in person and completes the pre-tour process. ",
        "Lyamii Tour Experts get assigned to each sold vacation IDs to verify the user profile and answer all queries and doubts of the user on a virtual meet. The LTEs also explain the daily itinerary, cultural importance of each location, the people, the language layers to the guest. ",
        "The LTEs also assist the user to understand his/her most suitable tour ID. The user may also register and merge new traveller IDs to the same tour ID during this session.",
        "We believe each of the tour IDs offer an unique flavour on the table, with the virtual meet Lyamii understands the traveller’s requirements and make sure the traveller is spending on the right vacation, with the best returns.",
        "Any coupon codes received by the user from any restaurant or hostel or youtube or instagram or on Lyamii brochure can be redeemed during the pre-tour documentation process. ",
        "",
      ],
    },
    {
      policy: "Cancellation Charges",
      text: [
        "Lyamii guarantees the most user-friendly cancellation and refund structure in the market. ",
        "Once a platform visitor books a tour ID on Lyamii he/she receives a confirmation call within 2-6 hours from our operations backend to check if the booking was placed by mistake. Lyamii charges 0% in these scenarios as we reflect the whole paid amount to the user's source account and share the TAT with the user.",
        "Once a platform visitor buys a tour ID on Lyamii he/she also receives a confirmation call within 2-4 hours from our operations backend to check if the purchase was completed by mistake. Lyamii charges 0% in these scenarios too, we reflect the whole paid amount to the user's source account and share the TAT with the user, no questions asked.",
        "If a purchased tour gets cancelled 20 days prior to the arrival date, 100% refund gets credited to the user's source account.",
        "If a purchased tour gets cancelled 14 days prior to the arrival date, we reflect 90% of the paid amount to the user's source account. The user will receive the remaining 10% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "If a purchased tour gets cancelled 7 days prior to the arrival date, 80% refund gets reverted to the source account. The user will receive another 10% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "If a user cancels a purchased tour ID within the week of arrival, he/she will receive a 70% refund to his/her source account. The user will receive another 5% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "If a user cancels a purchased tour ID on the day of arrival, he/she will receive a 30% refund to his/her source account. The user will receive another 20% in the form of coupons and may use these coupons to book a new tour ID or to redeem souvenirs.",
        "The minimum token amount of the booked tours are non refundable, although the user with a booked tour may reschedule the vacation with the same paid token money. But the rescheduled date shouldn't exceed 90 days from the previously booked vacation. ",
        "These foreign transactions go through a few government taxes, so 100% refund refers to the paid amount minus the taxes.",
        "The currency exchange rate always follows the live exchange value, in case of cash payments Lyamii operates offline transactions and currency exchange only in the common spaces of the 4 star plus property that the guest is staying in.",
        "In cases of domestic user booked tour IDs gets confirmed after the doorstep documentation and payment or the virtual meet documentation and payment. ",
      ],
    },
    {
      policy: "Refund Policy ",
      text: [
        "Lyamii’s payment and refund unit is committed to excellence and top notch user experience.",
        "Lyamii initiates refunds within 2 hours from the cancellation confirmation and shares the TAT with the user over mail highlighting the transaction ID. ",
        "All refunds get credited to the user's source account only, Lyamii doesn't engage with third party banking services. ",
        "Refund TAT depends on the user's payment medium. Some cards take 4-7 days to process refunds where upi payments take 2 hours.",
        "In cases of international users the refund amount might fluctuate a bit as the transactions go through live exchange value and a few government taxes.",
        "In cases of transaction failures the user may reach our Bootcamp with the transaction ID at connect.bootcamp@lyamii.com. Our dedicated team guarantees a solution within 24 hours.",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    },
  ],
};
