export const aboutText = [
  "Driven by a shared belief that travel has the power to broaden lifes, and is the key to self exploration, a group of passionate travel enthusiasts from India set out to develop Lyamii, a platform to simplify foreign tourism with pre-loaded vacation chains. Team Lyamii harnessed the power of technology and the love for exploration to create a complete architecture, where an explorer will find numerous portals to new landscapes and cultures.",

  "Lyamii started its journey as a foreign tourism business catering backpacking tours to Southeast Asian countries for fellow Indian solo travellers back in 2023. With love and support from our users we grew stronger and registered ourselves as a Private Limited company in January, 2024. With a completely upgraded JAVA backend on AWS and Microsoft database, Lyamii also has received the tech-startup recognition from the Indian government, DIPP167414. Post recognition Lyamii entered Europe, Africa and Australia, generating more vacation options for Indian backpackers.",

  "With many interactions with foreign travellers visiting India we realised the dynamic energy our country contains, it is very powerful and different. Although visiting India is in the bucket list of almost all global travellers, our guests were lacking a little assistance in order to experience the best of this big country. That is when we started crafting our Incredible India segment. In this segment we've covered all regions of India by connecting diverse cultures with compact itineraries.",

  "Team Lyamii is composed of diverse individuals, from seasoned world travellers, tech heads to active customer support and friendly volunteers, we have assembled a collective of talents who bring unique perspectives and skills to the platform. So far Lyamii has opened two office locations, 18 bootcamps and 64 helpdesks across India. In Lyamii the operations team is more than just a department; it's a commitment to excellence. It has 4 wings and 4 houses. The four wings are Documentation & Verification, Booking & Cancellation, Payment & Refund, Shipment & Warehouse. The wings handle the backend operations, and the houses work on Live Tour Assistance & Problem Solving. Four houses are virtually formed to provide round-the-clock multilingual support to our Users worldwide.",

  "The Travellers Unit of Lyamii carefully curated a selection of tours that take you off the beaten path and allow you to truly immerse yourself in the local culture. Whether you're exploring the ancient ruins of Greece, cruising through the breathtaking landscapes of New Zealand, or traversing the vibrant markets of Egypt, Lyamii vacations offer an authentic and enriching experience that goes beyond typical tourist attractions. We understand that affordability is a crucial factor when it comes to travel. That's why we have worked tirelessly to create partnerships with local businesses, accommodations, and transportation providers in each country, allowing us to offer competitive prices without compromising on quality. Our aim is to make world tourism accessible to everyone, regardless of their budget, so that more people can experience the joy of discovering new places and forging lifelong memories.",
];
export const team = [
  {
    img: require("../../../Assets/mainak.jpeg"),
    name: "Mainak",
    position: "CEO & CMO",
  },
  {
    img: require("../../../Assets/sourav.jpeg"),
    name: "Sourav",
    position: "CTO",
  },
  {
    img: require("../../../Assets/harpreet.png"),
    name: "Harpreet",
    position: "COO",
  },
  {
    img: require("../../../Assets/subhendu.jpeg"),
    name: "Subhendu",
    position: "CPO",
  },
  {
    img: require("../../../Assets/aritra.jpeg"),
    name: "Aritra",
    position: "CFO",
  },
  {
    img: require("../../../Assets/abhishek.jpeg"),
    name: "Abhishek",
    position: "Developer",
  },
  {
    img: require("../../../Assets/vaibhav.jpeg"),
    name: "Vaibhav",
    position: "Developer",
  },
  {
    img: require("../../../Assets/tiyasa.jpeg"),
    name: "Tiyasa",
    position: "Data Analyst",
  },
];

export const missions = [
  "Lyamii started with a vision to revolutionise the world of world tourism and to create huge number of employment with volunteering opportunities. Throughout history, our ancestors traversed vast terrains, crossing continents in search of sustenance, shelter, and new opportunities. The spirit of exploration flows within our DNA, intertwining with our curiosity and thirst for knowledge. It is encoded in the very fabric of our being, urging us to step outside of our comfort zones and immerse ourselves in the wonders that lie beyond our horizons. This genetic connection with travel fuels our desire to encounter diverse cultures, witness awe-inspiring landscapes, and engage with the intricate tapestry of humanity. With boundless vision and an insatiable curiosity for the World, we're aiming to inspire a new generation of Wanderers.",

  "Besides, travelling abroad often carries the misconception of being prohibitively expensive. However, with strategic planning exploring foreign lands can be surprisingly affordable. We work with no middleman to guarantee the best pricing in every segment. Join us to execute your perfect vacation abroad. To expand our reach and capabilities, Lyamii partners with key stakeholders in the tourism industry, including hostels, hotels, local guides, and transportation providers. These strategic alliances enable us to offer competitive prices, exclusive deals, and a wide array of options to our customers. Moreover, collaborating with local businesses in various destinations allows us to offer authentic experiences, further enhancing our appeal to international travellers. Lyamii leverages cutting-edge technology to streamline operations and enhance user experience. By automating routine processes and utilising data analytics, Lyamii can handle increased customer volumes without compromising on service quality.",

  "To book a vacation the visitor only needs to sign up and create his profile. Afterwards the visitor may place the book now option to initiate the process. The desired vacation will be reflected on the Upcoming Tours section once the traveller buys or books an item. From Lyamii the confirmation call and documentation will be conducted accordingly. After the documentation the Tour ID will be shared with the user and hosts. This ID will cover all possible requirement of the traveller once the tour gets live.",

  "At Lyamii, we also prioritise sustainability and responsible tourism practices. We strive to minimise our environmental impact by promoting eco-friendly accommodations, supporting local conservation efforts, and encouraging responsible behaviour among our travellers. We believe that by experiencing the beauty of our planet firsthand, we can inspire a deeper appreciation and commitment to its preservation. Join us on this incredible adventure as we connect countries, break down barriers, and make world tourism affordable for everyone. Let Lyamii be your trusted companion as you embark on a journey of discovery, cultural exchange, and personal growth. Together, let's create memories that will last a lifetime and contribute to a more interconnected and compassionate world.",
];

export const bootcamps = [
  { location: "DELHI", organiser: "Arkajit" },
  { location: "KOLKATA", organiser: "Krish" },
  { location: "MUMBAI", organiser: "Gajal" },
  { location: "CHENNAI", organiser: "Saswata" },
  { location: "HYDERABAD", organiser: "Nazim" },
  { location: "JAIPUR", organiser: "Prithvi" },
  { location: "LUCKNOW", organiser: "Irfan" },
  { location: "AMRITSAR", organiser: "Devnoor" },
  { location: "COCHIN", organiser: "Gayatri" },
  { location: "VADODARA", organiser: "Rashi" },
  { location: "SRINAGAR", organiser: "Danish" },
  { location: "PATNA", organiser: "Jyoti" },
  { location: "SHILONG", organiser: "Archie" },
  { location: "VARANASI", organiser: "Shivam" },
  { location: "GOA", organiser: "Dellon" },
  { location: "RISHIKESH", organiser: "Atharv" },
  { location: "PORT BLAIR", organiser: "Ivy" },
  { location: "KANPUR", organiser: "Arjun" },
];

